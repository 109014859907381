<template>
	<div class="loading">
		<article class="blog-post blog-post-list shadow-sm p-3 mb-5 border rounded" aria-hidden="true">
			<h4 class="blog-post-title placeholder-glow">
				<span class="placeholder col-12"></span>
			</h4>
			<p class="blog-post-meta placeholder-glow">
				<span class="placeholder col-3"></span> | 
				oleh <a href="#" class="placeholder-glow"><span class="placeholder col-2"></span></a> |
				<i class="bi bi-eye"></i> <span class="placeholder col-2"></span>
			</p>
			<div class="card-text row">
				<svg height="220" class="col-6">
					<rect width="100%" height="100%" fill="#868e96"></rect>
				</svg>
				<p class="col placeholder-glow d-flex flex-column gap-2">
					<span class="small placeholder col-12"></span>
					<span class="small placeholder col-11"></span>
					<span class="small placeholder col-10"></span>
					<span class="small placeholder"></span>
					<span class="small placeholder col-10"></span>
					<br>
					<br>
					<a href="#" class="placeholder-glow"><span class="placeholder col-4"></span></a>
				</p>
			</div>
			<div>
				<router-link to="#" class="small placeholder-glow"></router-link>   
			</div>
		</article>
	</div>
</template>